#cart-root {
    * {
        font-size: 16px;
    }

    button,
    input,
    select,
    textarea {
        font-family: MaisonNeue, sans-serif;
    }

    button:hover {
        opacity: 1;
    }

    :not( .mejs-button ) > button:hover,
    :not( .mejs-button ) > button:focus,
    input[type="button"]:hover,
    input[type="button"]:focus,
    input[type="submit"]:hover,
    input[type="submit"]:focus {
        background: unset;
    }

    button,
    input[type="button"],
    input[type="submit"] {
        padding: 0;
    }

    p,
    p.info {
        color: unset;
    }

    .btn-link,
    .btn-link:focus,
    .btn-link:hover {
        text-decoration: none;
    }

    ::-webkit-input-placeholder {
        color: #757575;
    }

    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="number"],
    input[type="tel"],
    input[type="range"],
    input[type="date"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="color"],
    textarea {
        color: unset;
        border-color: unset;
    }

    .header-cart {
        font-family: MaisonNeue-Book, sans-serif;
        @media (min-width: 768px) {
            .sticky & {
                position: fixed;
                width: 100%;
                left: 0;
                top: 0;
                z-index: 11;
            }
        }
        @media (max-width: 768px) {
            position: fixed !important;
            width: 100%;
            top: 0;
            z-index: 100;
            max-height: 100%;
            overflow: auto;
        }

        .checkout-header {
            background-color: #171717;
            padding: 14px 35px;
            display: none;
        }

        &.expanded {
            .checkout-header {
                //display: block;
            }
        }

        &.hidden {
            display: none;
        }

        .checkout-header, .checkout-header .bowl-block {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
        }

        .checkout-header h5 {
            margin: 0 7px;
        }

        .checkout-header .bowl-block span {
            background-color: #ff766f;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            padding: 3px 5px 1px 5px;
            cursor: pointer;
        }

        .order-info_top {
            background-color: #171717;
            color: #fff;
            padding: 0 23px 0 23px;
            position: relative;
            //top: -37px;
            left: 0;
            right: 0;
            z-index: 100;

            .all-info {
                //max-height: 0;
                overflow: hidden;
                transition: max-height .3s;
                @media (max-width: 768px) {
                    display: none;
                    margin-bottom: 71px;
                    padding: 0 18px;
                }
            }
        }


        &.expanded {
            .order-info_top {
                .all-info {
                    @media (max-width: 768px) {
                        max-height: none !important;
                        height: 100vh;
                        display: flex;
                        flex-direction: column;
                        //justify-content: space-between;
                        overflow: auto;
                    }
                }

                @media (max-width: 768px) {
                    height: 100vh;
                }
            }
        }

        .order-info_top table {
            width: 100%;
            //display: none;
            tbody {
                width: 100%;

                tr {
                    width: 100%;

                    &.coupon-tr {
                        td:first-child {
                            //width: 80%;
                            max-width: 250px;
                        }

                        td:last-child {
                            margin-left: auto;
                        }
                    }
                }
            }
        }


        &.expanded {
            .order-info_top table {
                //display: table;
            }
        }

        .order-info_top table th {
            text-align: left;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.58;
            letter-spacing: normal;
            color: #ffffff;
            padding: 10px 0;
        }

        .order-info_top table td {
            padding: 20px 0;
        }

        .order-info_top table td:last-child, .order-info_top table th:last-child {
            text-align: right;
        }

        .order-info_top .order-info_close {
            color: #fff;
        }

        .butter-treats_row {
            td .remove {
                opacity: 0.35;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                color: #ffffff;
            }
        }

        .order-info_top table .meal-plan td:first-child, .order-info_top table .butter-treats_row td:first-child {
            font-weight: 500;
            line-height: 1.19;
            letter-spacing: 1.6px;
            color: #ff7069;
            text-transform: uppercase;
        }

        .meal-plan {
            .edit-pet-input {
                border: 0;
                background-color: #171717;
                font-size: 16px;
                padding: .5rem;
            }
        }

        .order-info_top table .meal-plan td.empty {
            text-align: center;
        }

        .order-info_top table .meal-plan td .btn-link.edit, .order-info_top table .meal-plan td .btn-link.remove {
            opacity: 0.35;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            margin-left: 20px;
        }

        .order-info_top table .quantity {
            display: inline-block;
            width: 28px;
            padding: 3px 2px 2px 3px;
            border: 1px solid #b9b8b8;
            background-color: transparent;
            text-align: center;
            line-height: 1.5;
            color: #000;
        }

        .order-info_top table .quantity.active {
            background: #ff766f;
            border-color: #ff766f;
            color: white;
        }

        .order-info_top table .quantity:focus {
            outline: none;
        }

        .order-info_top table .quantity, .order-info_top table .minus-button, .order-info_top table .plus-button {
            color: #fff;
        }

        .order-info_top .shipping-table_block {
            max-width: 418px;
            margin-left: auto;
        }

        .order-info_top .shipping-table td:last-child {
            text-align: right;
        }

        .order-info_top .shipping-info_block {
            position: relative;
            padding-bottom: 28px;
            border-bottom: 1px solid white;
            //display: none;
        }

        .order-info_top {
            .butter-treats_row {
                .butter-quantity {
                    .remove {
                        margin-left: 20px;
                    }

                    .minus-button {
                        padding-right: 10px;
                    }

                    .plus-button {
                        padding-left: 10px;
                    }

                    input {
                        font-size: 12px;
                        font-weight: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        width: 32px;
                        height: 24px;
                        border-color: rgba(216, 216, 216, .2);
                    }
                }
            }
        }

        &.expanded {
            .order-info_top .shipping-info_block {
                //display: block;
            }
        }

        .order-info_top .shipping-info_block .add-meal {
            position: absolute;
            left: 0;
            bottom: 10px;
            text-transform: uppercase;
            color: white;
            padding-left: 35px;
        }

        .order-info_top .shipping-info_block .add-meal:after {
            position: absolute;
            content: "";
            left: 0;
            width: 21.5px;
            height: 10.5px;
            background-image: url("../../assets/img/arrow-left.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .order-info_top table td, .order-info_top table th {
            position: relative;
        }

        .order-info_top table tr {
            border-bottom: 1px solid rgba(216, 216, 216, 0.2);
        }

        .order-info_top table td:after, .order-info_top table th:after {
            position: absolute;
            content: "";
            height: 1px;
            background-color: #fff;
            left: 0;
            right: -2px;
            bottom: 0;
            opacity: .2;
            @media (max-width: 768px) {
                content: none;
            }
        }

        .order-info_top .total-price {
            font-size: 20px;
            line-height: 1.15;
            letter-spacing: 1px;
        }

        .order-info_top .shipping-table .plus-button,
        .order-info_top .shipping-table .minus-button {
            width: 21px;
            height: 21px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 1px solid white;
            background-color: transparent;
            background-image: url("../../assets/img/plus.png");
            background-repeat: no-repeat;
            background-position: center;
        }

        .order-info_top .shipping-table .plus-button {
            background-image: url("../../assets/img/plus.png");
        }

        .order-info_top .shipping-table .minus-button {
            background-image: url("../../assets/img/minus.png");
        }

        .order-info_top .shipping-table tr td:last-child {
            //padding-left: 10%;
        }

        .order-info_top .shipping-table tr td:first-child {
            //padding-right: 10%;
        }

        .order-info_top .shipping-table tr:last-child td:after {
            display: none;
        }

        .order-info_top {
            .error {
                position: static;
                color: #ff766f;
                margin-bottom: 0;
                margin-top: 8px;
            }

            .checkout-link {
                display: inline-block;
                width: 100%;
                line-height: 1.19;
                letter-spacing: 0.8px;
                color: #171717;
                background-color: white;
                text-transform: uppercase;
                padding: 19px 10px 15px;
                text-align: center;
                box-sizing: border-box;

                &.coupon {
                    width: inherit;
                    border: none;

                    &:disabled {
                        opacity: .5;
                    }
                }

                &.disabled {
                    &:hover {
                        opacity: .5 !important;
                    }

                    cursor: auto;
                    opacity: .5;
                }

                &.coupon {
                    line-height: 0;
                }

                @media (max-width: 768px) {
                    //position: fixed;
                    //left: 18px;
                    //bottom: 18px;
                    //width: calc(100% - 36px);
                }
            }

            .checkout-button-container {
                @media (max-width: 768px) {
                    width: 100%;
                    position: fixed;
                    left: 0;
                    bottom: 0;
                    padding: 0 18px 18px;
                    background-color: #171717;
                }

                .checkout-link {
                    display: inline-block;
                    width: 100%;
                    line-height: 1.19;
                    letter-spacing: 0.8px;
                    color: #171717;
                    background-color: white;
                    text-transform: uppercase;
                    padding: 19px 10px 15px;
                    text-align: center;
                    box-sizing: border-box;

                    &.coupon {
                        width: inherit;
                        border: none;

                        &:disabled {
                            opacity: .5;
                        }
                    }

                    &.disabled {
                        &:hover {
                            opacity: .5 !important;
                        }

                        cursor: auto;
                        opacity: .5;
                    }

                    &.coupon {
                        line-height: 0;
                    }

                    @media (max-width: 768px) {
                        //position: fixed;
                        //left: 18px;
                        //bottom: 18px;
                        //width: calc(100% - 36px);
                    }
                }
            }
        }


        .coupon-input {
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            color: white;
            font-size: 14px;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-box-shadow: 0 0 0 1000px transparent inset;
                -webkit-text-fill-color: #fff;
            }
        }

        .plus-icon {
            cursor: pointer;
        }

        .bowl-count_block {
            text-align: center;
            padding: 8px 0;
            position: relative;
            line-height: 24px;

            @media (max-width: 767px) {
                display: flex;
            }
        }

        .bowl-count_block .bowl-count_block__text {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.4;
            display: inline-block;
            margin: 0;

            span {
                font-size: 14px;
            }

            a {
                color: #ff766f;
                font-size: 14px!important;
                margin-left: 5px;

                @media (max-width: 767px) {
                    font-size: 10px;
                }
            }
        }

        .bowl-count_block .bowl-count_block__count {
            //float: right;
            display: flex;
            align-items: center;
            position: absolute;
            //padding-left: 24px;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            cursor: pointer;
        }

        .bowl-count_block .bowl-count_block__count img {
            margin-right: 5px;
        }

        .bowl-count_block .bowl-count_block__count p {
            display: inline-block;
            margin: 0;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.14;
            letter-spacing: 1.4px;

            &:hover {
                color: #FF766F;
            }
        }

        //.bowl-count_block .bowl-count_block__count:after {
        //    position: absolute;
        //    content: "";
        //    left: 0;
        //    top: 0;
        //    bottom: 0;
        //    margin: auto;
        //    //width: 20px;
        //    //height: 17px;
        //    background-color: transparent;
        //    background-image: url("../../assets/img/bowl-01.svg");
        //    background-repeat: no-repeat;
        //    background-position: center;
        //}

        .bowl-count_block .bowl-count_block__count .bowl-count {
            //padding: 5px;
            width: 17px;
            height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            //display: inline-block;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            color: #171717;
            background-color: #ff766f;
            font-size: 10.8px;
            font-weight: normal;
            padding-top: 1px;
            box-sizing: border-box;
            margin-left: 4px;

            &.empty {
                background-color: #fff;
            }
        }

        .mb10 {
            margin-bottom: 10px !important;
        }

        .f14 {
            font-size: 14px;
            line-height: 1.36;
        }

        .f12 {
            font-size: 12px;
            line-height: 1.58;
        }
    }

    @media only screen and (max-width: 768px) {
        .header-cart .order-info_top {
            //top: -156px;
        }

        .order-info_top {
            tr {
                display: flex;
                flex-wrap: wrap;

                .bowl-type, .bowl-quantity {
                    display: none;
                }
            }

            .order-info_close-col {
                &:after {
                    display: none;
                }

                .order-info_close {
                    position: absolute;
                    right: 0;

                    img {
                        max-width: none;
                    }
                }
            }

            th:first-child {
                width: 100%;
            }

            .meal-plan {
                padding-top: 10px;
                position: relative;

                td {
                    width: 100%;

                    &:not(:last-child,.meal-plan_quantity):after {
                        display: none;
                    }

                    &.meal-plan_price:after, &.meal-plan_type:after {
                        display: block;
                    }

                    &.meal-plan_quantity {
                        padding-top: 10px;
                        padding-bottom: 5px;
                        position: initial;

                        .remove {
                            position: absolute;
                            right: 0;
                            top: 30px;
                        }
                    }

                    &.meal-plan_type {
                        padding-top: 5px;
                    }
                }

                .meal-plan_type {
                    order: 2;
                }

                .meal-plan_price {
                    order: 3;
                }

                .meal-plan_type, .meal-plan_price {
                    width: 50%;
                }

                .meal-plan_title {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    justify-content: space-between;
                    padding-right: 86px;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-items: flex-start;

                    .edit {
                        position: relative;

                        &:after {
                            position: absolute;
                            content: "";
                            right: -12px;
                            width: 1px;
                            background: rgba(216, 216, 216, .6);
                            top: -4px;
                            bottom: -4px;
                        }
                    }
                }
            }

            .butter-treats_row {
                padding-top: 10px;

                td {
                    width: 100%;

                    &:after {
                        display: none;
                    }

                    .remove {
                        opacity: 0.35;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 1.19;
                        letter-spacing: normal;
                        float: right;
                        color: #fff;
                    }

                    &.butter-treats_type, &.butter-treats_price {
                        width: 50%;
                    }

                    &.butter-treats_type {
                        order: 2;
                    }

                    &.butter-treats_price {
                        order: 3;
                        padding-bottom: 30px;
                    }

                    &.butter-treats_type:after, &.butter-treats_price:after {
                        display: block;
                    }

                    &.butter-quantity {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

            .shipping-info_block {
                display: -webkit-box !important;
                display: -moz-box !important;
                display: -ms-flexbox !important;
                display: -webkit-flex !important;
                display: flex !important;
                flex-wrap: wrap;
                padding-top: 20px;
            }

            .add-meal {
                order: 1;
                position: relative !important;
                bottom: 0;
                display: inline-block;
                width: inherit;
                margin: auto;
            }

            .shipping-table_block {
                order: 2;
                width: 100%;
                max-width: 100% !important;
                margin-left: 0;

                .shipping-table {
                    td {
                        width: 50%;
                        padding-right: 0 !important;
                        padding: 25px 0;
                    }

                    tbody {
                        tr:first-child {
                            border-top: 1px solid rgba(216, 216, 216, .2);
                        }
                    }
                }
            }

            > table {
                margin-bottom: 40px;
            }

            .coupon-input {
                height: 100%;
            }
        }
    }

    .header-cart {
        height: 34px;

        &.expanded {
            @media (max-width: 768px) {
                height: 100%;
                .order-info_top {
                    min-height: calc(100% - 13px);
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    //padding: 0 18px 0;
                    padding: 0;

                    .add-meal {
                        margin: 0;
                    }
                }
                .checkout-header {
                    display: none;
                }
                .bowl-count_block {
                    display: none;
                }
                .shipping-info_block {
                    border-bottom: none;
                    padding-bottom: 0;
                    //margin-bottom: 50px;
                }
            }
        }
    }
}

.home #cart-root .header-cart .order-info_top .shipping-info_block .add-meal:after {
    top: 4px;
}

@media (max-width: 768px) {
    #cart-root .header-cart.expanded {
        overflow: hidden;
    }
    .home #cart-root .header-cart .order-info_top {
        //top: -158px !important;
    }
    .home #cart-root .header-cart.expanded .order-info_top {
        //top: -10px !important;
    }
}

